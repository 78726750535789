import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import Newsletter from '../components/Newsletter'
import { globalHistory } from '@reach/router'
import Content, {HTMLContent} from '../components/Content'
import GlobalStyle from "../styles/GlobalStyle"
import { format } from 'date-fns'

const ArticleContainer = tachyons('article')`
pv4-ns
`
const TitleWrapper = tachyons('div')`
wrap-nav tc pb5
`
const ArticleTitle = tachyons('h1')`
f3 f2-ns fw6 tc db pt5 pt6-ns mt0
`
const Cursor = styled('span')`
&:after {
  content: '|';
  font-weight: 500;
  font-size: 0.85em;
  color: var(--textPrimary);
  -webkit-animation: 0.8s blink step-end infinite;
  -moz-animation: 0.8s blink step-end infinite;
  -ms-animation: 0.8s blink step-end infinite;
  -o-animation: 0.8s blink step-end infinite;
  animation: 0.8s blink step-end infinite;
  }
@keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: var(--textPrimary);
  }
}
`

const ContentContainer = tachyons('div')`
wrap mw12
`
const ContentBody = tachyons('div')`
center measure-wide lh-copy mb5
`
const PrevNextContainer = tachyons('div')`
dt w-100 border-box wrap-nav tl pt3
`
const NextContainer = tachyons('div')`
dib w-100 w-50-l tr-l fn fr-l
`
const PrevContainer = tachyons('div')`
dib w-100 w-50-l tl fn fl-l mb3
`
const StyledLink = tachyons(Link)`
no-underline link-header lh-copy
`
const LinkHeader = tachyons('h4')`
mb2 mt0 link-header
`
const PaginateHeader = tachyons('p')`
mb1 light-silver fw4 f5 small
`
const ArticleDate = styled("h5")`
  color: var(--textSecondary);
  font-weight: var(--fontSemiBold);
`

export const BlogPostTemplate = ({
  title,
  date,
  readTime,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content
    return (
      <ArticleContainer>
        <GlobalStyle />
        <TitleWrapper>
          <ArticleTitle><Cursor>{title}</Cursor></ArticleTitle>
          <ArticleDate>
            {date} ·{" "}
            {readTime !== 1 ? `${readTime} mins read` : `${readTime} min read`}
          </ArticleDate>
        </TitleWrapper>
        <ContentContainer>
          <ContentBody>
            <PostContent content={content} />
          </ContentBody>
        </ContentContainer>
      </ArticleContainer>
    );
  }

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  readTime: PropTypes.string,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { allMarkdownRemark: posts } = data
  const postIndex = posts.edges.map(p => p.node.id).indexOf(post.id)
  const nextPost = posts.edges[postIndex + 1]
  const prevPost = posts.edges[postIndex - 1]
  const frontmatter = post.frontmatter
  const title = frontmatter.title
  const description = frontmatter.description
  const date = frontmatter.date
  const image = frontmatter.image
  const domain = data.site.siteMetadata.siteUrl

  return (
    <Layout contentComponent={HTMLContent}>
      <ArticleContainer>
        <BlogPostTemplate
          title={post.frontmatter.title}
          date={post.frontmatter.date}
          readTime={posts.edges[postIndex].node.timeToRead}
          description={post.frontmatter.description}
          content={post.html}
          contentComponent={HTMLContent}
        />
        <Helmet
          titleTemplate="%s"
          htmlAttributes={{
            class: "blog"
          }}
        >
          <title>{`${title} · Ajmal Afif`}</title>
          <meta name="description" content={`${description}`} />
          <meta
            name="image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />
          <meta name="twitter:title" content={`${title} · Ajmal Afif`} />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />
          <meta name="twitter:card" content="summary" />
          <meta property="og:title" content={`${title} · Ajmal Afif`} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />
          <meta property="og:type" content="article" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              mainEntityOfPage: `${domain}`,
              url: `${domain}${globalHistory.location.pathname}`,
              datePublished: `${date}`,
              dateModified: Date(),
              headline: `${title}`,
              name: `${title}`,
              description: `${description}`,
              image: {
                "@type": "ImageObject",
                url: `${domain}${image.childImageSharp.fluid.src}`
              },
              author: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              copyrightHolder: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              copyrightYear: `${format(new Date(), "yyyy")}`,
              creator: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              publisher: {
                "@type": "Person",
                name: "Ajmal Afif",
                logo: {
                  "@type": "ImageObject",
                  url: `${domain}/img/og-image-square.png`
                }
              }
            })}
          </script>
        </Helmet>
        <PrevNextContainer>
          {prevPost && (
            <PrevContainer>
              <PaginateHeader>← Previous</PaginateHeader>
              <LinkHeader>
                <StyledLink to={`${prevPost.node.fields.slug}`}>
                  {prevPost.node.frontmatter.title}
                </StyledLink>
              </LinkHeader>
            </PrevContainer>
          )}
          {nextPost && (
            <NextContainer>
              <PaginateHeader>Next →</PaginateHeader>
              <LinkHeader>
                <StyledLink to={`${nextPost.node.fields.slug}`}>
                  {nextPost.node.frontmatter.title}
                </StyledLink>
              </LinkHeader>
            </NextContainer>
          )}
        </PrevNextContainer>
      </ArticleContainer>
      <Newsletter />
    </Layout>
  );
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostTemplateByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          tableOfContents(
            absolute: false
            maxDepth: 3
          )
          timeToRead
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
